import { PatientService } from '@/services/patient.service';
export default {
  props: {
    rowData: {
      type: Object,
      default() {
        return {
          firstname: '',
          lastname: ''
        };
      }
    }
  },
  data() {
    return {
      showDialog: false,
      selectedYear: new Date().getFullYear()
    };
  },
  computed: {
    yearOptions() {
      const currentYear = new Date().getFullYear();
      return this.range(2021, currentYear);
    }
  },
  methods: {
    range(start, end) {
      if (start === end) return [start];
      return [start, ...this.range(start + 1, end)];
    },
    async download(row, selectedYear) {
      try {
        const res = await PatientService.getNDCForm(row.id, selectedYear);
        let name = '';
        if (row.firstname) {
          name.concat(row.firstname);
        }
        if (row.firstname && row.lastname) {
          name.concat(' ');
        }
        if (row.lastname) {
          name.concat(row.lastname);
        }
        console.log(res);
        if (res.data) this.getFile(res.data, name);
      } catch (err) {
        // console.log(err.status);
        if (err.status == 404) {
          this.$emit('show-toast', 'There are no NDC documents available for this year.');
        } else {
          this.$emit('show-toast', err.message);
        }
      }
    },
    getFile(url, name) {
      let link = document.createElement('a');
      link.href = url;
      let currentDate = new Date();
      if (name) name = ' ' + name;
      link.download = `NDC - ${currentDate}${name}`;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};